import React from "react"
import { LoginFormValues } from "../Account/LoginForm"
import { BaseSuccessStatusResponse } from "../../redux/models"
import MobileHeader from "./MobileHeader"
import DesktopHeader from "./DesktopHeader"
import { Branding } from "../../redux/types/BrandingTypes"

export interface LinkProps {
  name: string
  slug: string
}
export interface DropDownLinksProps {
  [key: string]: Array<LinkProps>
}

export interface DropDownColumnProps {
  title: string
  titleLink?: string | undefined
  links: Array<LinkProps>
}

export interface ConnectedHeaderProps {
  data: Array<{
    title?: string
    text: string
    allSlug?: string
    dropDownLinks?: DropDownLinksProps
    dropDownColumns?: Array<DropDownColumnProps>
    /**
     * to: Provide to make direct link
     */
    to?: string
  }>
  isConnected?: boolean
  isSideBarOpen?: boolean
  path?: string
  onLogin?: (values: LoginFormValues) => Promise<BaseSuccessStatusResponse>
  onSignup?: (values: LoginFormValues) => Promise<BaseSuccessStatusResponse>
  branding?: Branding
  hasBodyFitFullScreenLayout?: boolean
  ref?: any
}
export interface HeaderProps extends ConnectedHeaderProps {}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <>
      <MobileHeader {...props} />
      <DesktopHeader {...props} />
    </>
  )
}

Header.defaultProps = {
  branding: Branding.sixthreezero,
}

export default Header
