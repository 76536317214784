import React, { Fragment } from "react"
import { Box, Flex, FlexProps, Icon, Text } from "@chakra-ui/core"
import { Link } from "gatsby"
import { LinkProps } from ".."
import SectionTitle from "../SectionTitle"
import NavColumn from "../NavColumn"

import "./style.css";


type TwoColumnNavProps = Omit<FlexProps, "children"> & {
  columnOne: Array<LinkProps>
  columnTwo: Array<LinkProps>
  columnOneTitle: string
  columnOneUrl?: string
  columnTwoTitle: string
  onLinkClick: VoidFunction
  columns: {
    title: string
    titleLink?: string
    links: Array<LinkProps>
  }[]
  onShowChange?: (showAll: boolean) => void
}

export default function TwoColumnNav({
  columnOneTitle,
  columnTwoTitle,
  columnOneUrl,
  columnOne,
  columnTwo,
  onLinkClick,
  columns,
  onShowChange,
  ...props
}: TwoColumnNavProps) {
  return (
    <Flex px={columns.length ? "2rem" : "4rem"} justify="center" {...props}>
      <Box className="no-wrap">
        {columnOneUrl ? (
          <Link to={columnOneUrl} onClick={onLinkClick}>
            <SectionTitle>
              {columnOneTitle}
              <Icon
                name="chevron"
                size="1.25rem"
                transform="rotate(180deg)"
                ml="0.7rem"
              />
            </SectionTitle>
          </Link>
        ) : (
          <SectionTitle>{columnOneTitle}</SectionTitle>
        )}

        {columnOne.map(({ name, slug }) => (
          <Link
            to={slug}
            onClick={onLinkClick}
            key={`${columnOneTitle}-${slug}`}
          >
            <Text
              fontFamily="din"
              fontWeight="bold"
              fontSize="1.25rem"
              lineHeight="1.375rem"
              letterSpacing="2px"
              color="night"
              textTransform="uppercase"
              marginBottom="0.75rem"
            >
              {name}
            </Text>
          </Link>
        ))}
      </Box>
      <Box h="auto" w="1px" bg="dividerLine" m={columns.length ? "0 0 0 2rem" : "0 0 0 4rem"} />
      <NavColumn
        onLinkClick={onLinkClick}
        key={columnTwoTitle}
        title={columnTwoTitle}
        links={columnTwo}
        wrap={columns.length ? false : true}
        onShowChange={onShowChange}
        borderRight={columns.length ? "1px solid" : undefined}
        borderColor="dividerLine"
        px={columns.length ? "2rem" : "4rem"}
      />
      {columns.map((column, index) => (
              <Fragment key={column.title}>
                <NavColumn
                  key={column.title}
                  title={column.title}
                  titleLink={column.titleLink}
                  links={column.links}
                  onLinkClick={onLinkClick}
                  onShowChange={onShowChange}
                  borderRight={index !== columns.length - 1 ? "1px solid" : undefined}
                  borderColor="dividerLine"
                  px="2rem"
                />
              </Fragment>
            ))}
    </Flex>
  )
}
